import tw from "twin.macro"

import { TitleProps } from "components/dynamic"
import { H3, H6 } from "styles/Text"
import Container from "components/Container"
import Language from "components/Language"

const TitleComponent = ({ PreTitle, Title, Content }: TitleProps) => (
  <Container tw="mt-20 mb-7.5 text-center lg:(mt-30 mb-15)">
    {PreTitle && (
      <H6 as="div" tw="text-primary mb-5 lg:mb-6">
        <Language unwrap>{PreTitle}</Language>
      </H6>
    )}
    {Title && (
      <H3 as="h2">
        <Language unwrap>{Title}</Language>
      </H3>
    )}
    {Content && (
      <Language css={{ width: "700px", maxWidth: "100%" }} tw="mx-auto">
        {Content}
      </Language>
    )}
  </Container>
)

export default TitleComponent
